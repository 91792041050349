import React from "react"
import { Link, graphql } from "gatsby"
import styled from "styled-components"

import { GatsbyProps } from "Src/global-types"

import Card, { CardImage } from "Components/Card"
import StandardLayout from "Components/core/StandardLayout"
import SEO from "Components/core/SEO"

export const CardFlex = styled.ul`
  display: flex;
  flex-wrap: wrap;
  margin: 1rem 0 0 0;
`

interface QueryData {
  liamMylesIndent: CardImage
  shatter: CardImage
  dcuBuilding: CardImage
  griffinTrainer: CardImage
  zooSafari: CardImage
  superHexagonClone: CardImage
  twitterGeoHeatmap: CardImage
  behaviourismSite: CardImage
  steamPunkSite: CardImage
  dcuUnity: CardImage
  yahtzee: CardImage
  profileProject: CardImage
  thesis: CardImage
}
interface UniversityProjectsProps {
  data: QueryData
}

const UniversityProjects: React.FC<UniversityProjectsProps & GatsbyProps> = ({
  data,
  location,
}) => {
  return (
    <StandardLayout location={location}>
      <SEO title="University Projects" />
      <h1>Uni Projects</h1>
      <h2>2011</h2>
      <Year2011 data={data} />
      <h2>2012</h2>
      <Year2012 data={data} />
      <h2>2013</h2>
      <Year2013 data={data} />
      <h2>2014</h2>
      <Year2014 data={data} />
      <h2>2015</h2>
      <Year2015 data={data} />
    </StandardLayout>
  )
}

const Year2011: React.FC<{ data: QueryData }> = ({ data }) => {
  return (
    <CardFlex>
      <Card imageFile={data.zooSafari} title="Safari Game">
        <Link className="multiline" to="/projects/university/safari-game/">
          Read about my Safari Game
        </Link>
      </Card>
    </CardFlex>
  )
}

const Year2012: React.FC<{ data: QueryData }> = ({ data }) => {
  return (
    <CardFlex>
      <Card imageFile={data.dcuBuilding} title="DCU Campus Tour">
        <Link className="multiline" to="/projects/university/dcu-campus-tour/">
          Read about my DCU tour app
        </Link>
      </Card>
      <Card imageFile={data.griffinTrainer} title="Griffin Game">
        <Link className="multiline" to="/projects/university/griffin-game">
          Read about my Griffin Trainer game
        </Link>
      </Card>
      <Card imageFile={data.profileProject} title="First Website">
        <Link className="multiline" to="/projects/university/first-website/">
          Check out my first website
        </Link>
      </Card>
    </CardFlex>
  )
}

const Year2013: React.FC<{ data: QueryData }> = ({ data }) => {
  return (
    <CardFlex>
      <Card imageFile={data.yahtzee} title="Yahtzee Game">
        <Link className="multiline" to="/projects/university/yahtzee">
          Check out my Yahtzee Game
        </Link>
      </Card>

      <Card imageFile={data.steamPunkSite} title="Steampunk Site">
        <Link className="multiline" to="/projects/university/steampunk-site/">
          Check out my steampunk site
        </Link>
      </Card>
      <Card imageFile={data.behaviourismSite} title="Behaviourism Essay">
        <Link
          className="multiline"
          to="/projects/university/behaviourism-essay/"
        >
          Check out my Behaviourism Essay Site
        </Link>
      </Card>
    </CardFlex>
  )
}

const Year2014: React.FC<{ data: QueryData }> = ({ data }) => {
  return (
    <CardFlex>
      <Card imageFile={data.superHexagonClone} title="Hexagon Clone">
        <Link className="multiline" to="/projects/university/hexagon-clone">
          Read about my Hexagon Clone
        </Link>
      </Card>
      <Card imageFile={data.twitterGeoHeatmap} title="Twitter Heatmap">
        <Link className="multiline" to="/projects/university/twitter-heatmap">
          Read about my Twitter Heatmap
        </Link>
      </Card>
      <Card imageFile={data.liamMylesIndent} title="Liam Myles Indent">
        <Link className="multiline" to="/projects/university/liam-myles-indent">
          Check out My 3D Indent
        </Link>
      </Card>
      <Card imageFile={data.shatter} title="Shatter Animation">
        <Link className="multiline" to="/projects/university/shatter-animation">
          Check out Shatter Animation
        </Link>
      </Card>
    </CardFlex>
  )
}

const Year2015: React.FC<{ data: QueryData }> = ({ data }) => {
  return (
    <CardFlex>
      <Card imageFile={data.thesis} title="Thesis">
        <Link className="multiline" to="/projects/university/thesis">
          Check out my thesis project
        </Link>
      </Card>
    </CardFlex>
  )
}

export default UniversityProjects

export const query = graphql`
  query {
    liamMylesIndent: file(relativePath: { glob: "animation/liam*" }) {
      ...CardImage
    }
    shatter: file(relativePath: { glob: "animation/time*" }) {
      ...CardImage
    }
    dcuBuilding: file(
      relativePath: { glob: "flash-projects/dcu-buildings-1*" }
    ) {
      ...CardImage
    }
    griffinTrainer: file(
      relativePath: { glob: "flash-projects/griffin-trainer-1*" }
    ) {
      ...CardImage
    }
    zooSafari: file(relativePath: { glob: "flash-projects/zoo-safari-001*" }) {
      ...CardImage
    }
    superHexagonClone: file(
      relativePath: { glob: "processing/super-hexagon-clone-2*" }
    ) {
      ...CardImage
    }
    twitterGeoHeatmap: file(
      relativePath: { glob: "processing/twitter-geo-heatmap-1*" }
    ) {
      ...CardImage
    }
    behaviourismSite: file(relativePath: { glob: "websites/behaviourism*" }) {
      ...CardImage
    }
    steamPunkSite: file(relativePath: { glob: "websites/steampunk*" }) {
      ...CardImage
    }
    dcuUnity: file(relativePath: { glob: "websites/unity*" }) {
      ...CardImage
    }
    yahtzee: file(relativePath: { glob: "websites/yahtzee*" }) {
      ...CardImage
    }
    profileProject: file(relativePath: { glob: "websites/david-mills*" }) {
      ...CardImage
    }
    thesis: file(relativePath: { glob: "art/ascended-realm*" }) {
      ...CardImage
    }
  }
`
