import React from "react"
import { graphql } from "gatsby"
import Img, { FixedObject } from "gatsby-image"

import * as CardStyle from "./styles"

export interface CardImage {
  childImageSharp: {
    fixed: FixedObject
  }
}
interface CardProps {
  imageFile: CardImage
  title: string
  children?: React.ReactChildren | React.ReactChild
}

const Card: React.FunctionComponent<CardProps> = ({
  imageFile,
  title,
  children,
}) => {
  return (
    <CardStyle.wrapper>
      <h3>{title}</h3>
      {imageFile && <Img fixed={imageFile.childImageSharp.fixed} />}
      {children}
    </CardStyle.wrapper>
  )
}

export const query = graphql`
  fragment CardImage on File {
    childImageSharp {
      fixed(width: 280, height: 200) {
        ...GatsbyImageSharpFixed
      }
    }
  }
`

export default Card
