import styled from "styled-components"

export const wrapper = styled.li`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 2rem 2rem 0;
  padding: 1rem;
  border: solid 0.1rem #a74fa3;
  width: 30rem;
  border-radius: 1.5rem;
  box-shadow: 0px 0 3px 0.2rem #806eff;
  h3 {
    text-align: center;
  }
  .gatsby-image-wrapper {
    border-radius: 1.5rem;
    border: solid 0.1rem #a74fa3;
    box-shadow: 0px 0 3px 0.2rem #806eff;
    margin: 1rem 0;
  }
`
